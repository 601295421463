import {
  Payment,
  PayTransfer as PayTransferDto,
  PrepareTransferDownload,
  Shop,
  VerifyPayment,
} from '@generated/models';
import {Transfer} from '@generated/models/transfer';
import {createAction, props} from '@ngrx/store';

const DownloadsActionsTypes = {
  GET_DOWNLOADABLE_TRANSFER: '[FREE AREA > Download] GetTransfer',
  GET_DOWNLOADABLE_TRANSFER_SUCCESS:
    '[FREE AREA > Download] GetTransferSuccess',
  GET_DOWNLOADABLE_TRANSFER_FAIL: '[FREE AREA > Download] GetTransferFail',
  PREPARE_DOWNLOAD: '[FREE AREA > Download] PrepareDownload',
  PREPARE_DOWNLOAD_SUCCESS: '[FREE AREA > Download] PrepareDownloadSuccess',
  PREPARE_DOWNLOAD_FAIL: '[FREE AREA > Download] PrepareDownloadFail',
  PAY_TRANSFER: '[FREE AREA > Download] PayTransfer',
  PAY_TRANSFER_SUCCESS: '[FREE AREA > Download] PayTransferSuccess',
  PAY_TRANSFER_FAIL: '[FREE AREA > Download] PayTransferFail',
  VERIFY_TRANSFER_PAYMENT: '[FREE AREA > Download] VerifyTransferPayment',
  VERIFY_TRANSFER_PAYMENT_SUCCESS:
    '[FREE AREA > Download] VerifyTransferPaymentSuccess',
  VERIFY_TRANSFER_PAYMENT_FAIL:
    '[FREE AREA > Download] VerifyTransferPaymentFail',
  READ_SHOP_BY_USER: '[FREE AREA > Download] read shop by user',
  READ_SHOP_BY_USER_SUCCESS: '[FREE AREA > Download] read shop by user success',
  READ_SHOP_BY_USER_FAILED: '[FREE AREA > Download] read shop by user failed',
  DOWNLOAD_PREVIEW: '[FREE AREA > Download] download preview',
  DOWNLOAD_PREVIEW_SUCCESS: '[FREE AREA > Download] download preview success',
  DOWNLOAD_PREVIEW_FAIL: '[FREE AREA > Download] download preview fail',
} as const;

type DownloadsActionsTypes =
  (typeof DownloadsActionsTypes)[keyof typeof DownloadsActionsTypes];

export const GetDownloadableTransfer = createAction(
  DownloadsActionsTypes.GET_DOWNLOADABLE_TRANSFER,
  props<{
    payload: {transferId: string | null; recipientId: string | null};
    context: 'download' | 'checkout';
  }>(),
);

export const GetDownloadableTransferSuccess = createAction(
  DownloadsActionsTypes.GET_DOWNLOADABLE_TRANSFER_SUCCESS,
  props<{
    payload: Transfer;
    context: 'download' | 'checkout';
  }>(),
);

export const GetDownloadableTransferFail = createAction(
  DownloadsActionsTypes.GET_DOWNLOADABLE_TRANSFER_FAIL,
);

export const PayTransfer = createAction(
  DownloadsActionsTypes.PAY_TRANSFER,
  props<{transferId: string; payload: PayTransferDto}>(),
);
export const PayTransferSuccess = createAction(
  DownloadsActionsTypes.PAY_TRANSFER_SUCCESS,
);
export const PayTransferFail = createAction(
  DownloadsActionsTypes.PAY_TRANSFER_FAIL,
);

export const PrepareDownload = createAction(
  DownloadsActionsTypes.PREPARE_DOWNLOAD,
  props<{
    payload: PrepareTransferDownload;
    openFile: boolean;
  }>(),
);

export const PrepareDownloadSuccess = createAction(
  DownloadsActionsTypes.PREPARE_DOWNLOAD_SUCCESS,
  props<{
    payload: PrepareTransferDownload;
    url?: string;
  }>(),
);

export const PrepareDownloadFail = createAction(
  DownloadsActionsTypes.PREPARE_DOWNLOAD_FAIL,
);

export const VerifyTransferPayment = createAction(
  DownloadsActionsTypes.VERIFY_TRANSFER_PAYMENT,
  props<{
    transferId: string;
    payload: VerifyPayment;
  }>(),
);

export const VerifyTransferPaymentSuccess = createAction(
  DownloadsActionsTypes.VERIFY_TRANSFER_PAYMENT_SUCCESS,
  props<{
    payload: Payment;
  }>(),
);

export const VerifyTransferPaymentFail = createAction(
  DownloadsActionsTypes.VERIFY_TRANSFER_PAYMENT_FAIL,
);

export const ReadShopByUser = createAction(
  DownloadsActionsTypes.READ_SHOP_BY_USER,
  props<{userId: string}>(),
);

export const ReadShopByUserSuccess = createAction(
  DownloadsActionsTypes.READ_SHOP_BY_USER_SUCCESS,
  props<{shop: Shop}>(),
);

export const ReadShopByUserFailed = createAction(
  DownloadsActionsTypes.READ_SHOP_BY_USER_FAILED,
);

export const DownloadPreview = createAction(
  DownloadsActionsTypes.DOWNLOAD_PREVIEW,
  props<{transferId: string}>(),
);

export const DownloadPreviewSuccess = createAction(
  DownloadsActionsTypes.DOWNLOAD_PREVIEW_SUCCESS,
);

export const DownloadPreviewFail = createAction(
  DownloadsActionsTypes.DOWNLOAD_PREVIEW_FAIL,
);
